import React, { Suspense, Fragment, useContext, useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme1 } from "src/theme";
import UserContext from "src/context/User";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsContext from "src/context/SettingsContext";
import CookieConsentBanner from "./component/CookieConsentBanner";

function App() {
  const themeSeeting = useContext(SettingsContext);
  const theme = createTheme1({
    theme: themeSeeting.settings.theme,
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CookieConsentBanner />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <UserContext>
              <BrowserRouter>
                <RefCodeCapture />
                <RenderRoutes data={routes} />
              </BrowserRouter>
              <ToastContainer />
            </UserContext>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

function RefCodeCapture() {
  const location = useLocation();

  useEffect(() => {
    // Extract the referral code from the URL
    const query = new URLSearchParams(location.search);
    const refCode = query.get("ref-code");
    console.log(refCode);
    if (refCode) {
      // Store the referral code in localStorage
      localStorage.setItem("referralCode", refCode);
    }
  }, [location]);

  return null;
}
