import { toast } from "react-toastify";

const unisatUtils = (() => {
  let unisat = null;
  let accountChangeListener = null;
  let networkChangeListener = null;
  let chainChangeListener = null;

  const FRACTAL_MAINNET = {
    enum: "FRACTAL_BITCOIN_MAINNET",
    name: "Fractal Mainnet",
  };

  const initUniSat = () => {
    if (!unisat) {
      unisat = window.unisat;
      if (!unisat) {
        return null;
      }
    }
    return unisat;
  };

  const connectWallet = async () => {
    let unisat = initUniSat();
    if (!unisat) {
      toast.error("UniSat Wallet is not installed!");
      return null;
    }

    try {
      const accounts = await unisat.requestAccounts();
      await ensureCorrectFractalChain(); // Ensure connection to Fractal mainnet/testnet

      return accounts[0]; // Return the first account (BTC address)
    } catch (error) {
      toast.error("Failed to connect to UniSat Wallet.");
      return null;
    }
  };

  const ensureCorrectFractalChain = async () => {
    const currentChain = await getChain();

    console.log("Current chain:", currentChain);

    if (currentChain.enum !== FRACTAL_MAINNET.enum) {
      try {
        // Attempt to switch to Fractal Mainnet
        const switchedChain = await switchChain(FRACTAL_MAINNET.enum);
        console.log("Switched chain:", switchedChain);

        // Ensure we're staying on Fractal Mainnet only, and do not attempt to switch to Testnet
        if (switchedChain && switchedChain.enum === FRACTAL_MAINNET.enum) {
          return switchedChain;
        } else {
          throw new Error("Fractal Mainnet is unavailable");
        }
      } catch (error) {
        toast.error("Failed to switch to Fractal Mainnet.");
      }
    }
  };

  const discconnectWallet = async () => {
    if (!unisat) return null;

    // Check if the user is connected to UniSat
    const isConnected = await unisat.isConnected(); // Assuming `unisat.isConnected()` is available

    if (!isConnected) {
      console.log("Not connected to UniSat Wallet, no need to disconnect.");
      return null;
    }

    try {
      await unisat.disconnect();
      console.log("Disconnected from UniSat Wallet.");
      return true;
    } catch (error) {
      toast.error("Failed to disconnect to UniSat Wallet.");
      return null;
    }
  };

  const getPublicKey = async () => {
    if (!unisat) return null;

    try {
      const publicKey = await unisat.getPublicKey();
      return publicKey;
    } catch (error) {
      toast.error("Failed to retrieve public key.");
      return null;
    }
  };

  const getBalance = async () => {
    if (!unisat) return null;

    try {
      const balance = await unisat.getBalance();
      return balance;
    } catch (error) {
      toast.error("Failed to retrieve balance.");
      return null;
    }
  };

  const getNetwork = async () => {
    if (!unisat) return null;

    try {
      const network = await unisat.getNetwork();
      return network;
    } catch (error) {
      toast.error("Failed to retrieve network.");
      return null;
    }
  };

  const getChain = async () => {
    if (!unisat) return null;

    try {
      const chain = await unisat.getChain();
      return chain;
    } catch (error) {
      toast.error("Failed to retrieve chain.");
      return null;
    }
  };

  const switchNetwork = async (network) => {
    if (!unisat) return null;

    try {
      const newNetwork = await unisat.switchNetwork(network);
      toast.success(`Switched to network: ${newNetwork}`);
      return newNetwork;
    } catch (error) {
      toast.error("Failed to switch network.");
      return null;
    }
  };

  const switchChain = async (chainEnum) => {
    if (!unisat) return null;

    try {
      const newChain = await unisat.switchChain(chainEnum);
      toast.success(`Switched to chain: ${newChain.name}`);
      return newChain;
    } catch (error) {
      toast.error("Failed to switch chain.");
      return null;
    }
  };

  const ensureCorrectNetwork = async (desiredNetwork) => {
    if (!unisat) return;
    const currentNetwork = await getNetwork();
    if (currentNetwork !== desiredNetwork) {
      return await switchNetwork(desiredNetwork);
    }
    return currentNetwork;
  };

  const ensureCorrectChain = async (desiredChain) => {
    if (!unisat) return;
    const currentChain = await getChain();
    if (currentChain.enum !== desiredChain.enum) {
      toast.warning(`Switching to the correct chain: ${desiredChain.name}`);
      return await switchChain(desiredChain.enum);
    }
    return currentChain;
  };

  const handleAccountChange = (callback) => {
    if (!initUniSat()) return null;
    accountChangeListener = (accounts) => callback(accounts[0]);
    unisat.on("accountsChanged", accountChangeListener);
  };

  const handleNetworkChange = (callback) => {
    if (!unisat) return;
    networkChangeListener = (network) => callback(network);
    unisat.on("networkChanged", networkChangeListener);
  };

  const handleChainChange = (callback) => {
    if (!initUniSat()) return;
    chainChangeListener = (chain) => callback(chain);
    unisat.on("chainChanged", chainChangeListener);
  };

  const removeListeners = () => {
    if (!initUniSat()) return;

    if (accountChangeListener) {
      unisat.removeListener("accountsChanged", accountChangeListener);
    }
    if (networkChangeListener) {
      unisat.removeListener("networkChanged", networkChangeListener);
    }
    if (chainChangeListener) {
      unisat.removeListener("chainChanged", chainChangeListener);
    }

    console.log("Removed all UniSat event listeners.");
  };

  return {
    connectWallet,
    discconnectWallet,
    getPublicKey,
    getBalance,
    getNetwork,
    getChain,
    switchNetwork,
    switchChain,
    ensureCorrectNetwork,
    ensureCorrectChain,
    handleAccountChange,
    handleNetworkChange,
    handleChainChange,
    removeListeners,
  };
})();

export default unisatUtils;
