import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    backgroundRepeat: "repeat",
    background: "#fff",
    backgroundImage: "url(/images/light.jpg)" /* fallback */,
    backgroundSize: "100%",
    backgroundPosition: "top",
  },
  root1: {
    backgroundColor: "#000",
    backgroundRepeat: "repeat",
    background: "#000",
    backgroundImage: "url(/images/Dark_Theme.webp)" /* fallback */,
    backgroundSize: "100%",
    backgroundPosition: "top",
  },
  MainLayout: {
    zIndex: "1",
    position: "relative",
    minHeight: "calc(100vh - 415px)",
    // paddingTop: "70px",
  },
  stickyHeader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.default,
    transition: "background-color 0.3s ease",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <div
        className={
          themeSeeting.settings.theme === "DARK" ? classes.root1 : classes.root
        }
      >
        <TopBar isScrolled={isScrolled} />
      </div>

      <div
        style={{
          paddingTop: "70px",
          ...(history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }),
        }}
      ></div>

      <div className={classes.MainLayout}>
        {" "}
        <Box className={classes.shade}></Box>
        <Box className={classes.shade1}></Box>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
