import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "4px 12px",
    width: "fit-content",
    border: "1px solid #C8C821",
    marginLeft: "15px",
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  text: {
    color: "#C8C821",
    fontWeight: "bold",
  },
}));

const PointsBadge = ({ value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.badge}>
      <img
        src="./images/Crystal_Muse_token_icon.png"
        alt="Icon"
        className={classes.icon}
      />
      <Typography variant="body2" className={classes.text}>
        {value}
      </Typography>
    </Box>
  );
};

export default PointsBadge;
