import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import unisatUtils from "src/utils/unisat";
import { UserContext } from "src/context/User";

const BtcWalletButton = () => {
  const user = useContext(UserContext);

  const handleBtcConnect = async () => {
    try {
      // Connect the wallet
      const walletAddress = await unisatUtils.connectWallet();

      if (walletAddress) {
        user.setUnisatAccount(walletAddress);
      }
    } catch (error) {
      console.error("Error connecting to UniSat Wallet:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        whiteSpace: "pre",
        fontWeight: "500",
        color: "white",
        fontSize: "13px",
        marginLeft: "15px",
      }}
      onClick={handleBtcConnect}
    >
      Connect BTC Wallet
    </Button>
  );
};

export default BtcWalletButton;
