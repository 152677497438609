import React, { useEffect, useRef } from "react";

export default function ModelViewer({ url, height, width }) {
  return (
    <model-viewer
      src={url}
      // src="https://api.brc420.io/content/b4213ce7b2e7d5f53fd6628907eb37812433d3e5c8039f13257c70c45b52b89ei0"
      camera-controls
      style={{ width: height, height: width }}
      alt="A 3D model"
    />
  );
}
