import React from "react";
import { GLTFModel, AmbientLight, DirectionLight } from "react-3d-viewer";

export default function View3DFile({ url }) {
  return (
    <GLTFModel
      src={url}
      // src="https://s3.eu-north-1.amazonaws.com/cdn.arrival.so/uploads/172076611595417207661095431355.glb"
    >
      <AmbientLight color={0xffffff} />
      <DirectionLight color={0xffffff} position={{ x: 100, y: 200, z: 100 }} />
      <DirectionLight
        color={0xff00ff}
        position={{ x: -100, y: 200, z: -100 }}
      />
    </GLTFModel>
  );
}
